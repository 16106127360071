
import { defineComponent, ref, onMounted } from "vue";
import ExportCustomerModal from "@/components/modals/forms/ExportCustomerModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import AddProductListSalesEnquiry from "@/components/modals/forms/AddProductListSalesEnquiry.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";
import { mask } from "vue-the-mask";
import { ActionsFi } from "@/store/enums/StoreFiEnums";
import { useRouter } from "vue-router";
import moment from "moment";

export default defineComponent({
  name: "customers-listing",
  directives: { mask },
  components: {
    ExportCustomerModal,
    AddProductListSalesEnquiry,
  },
  methods: {
    onChange() {
      if (this.formData.pincode == "") {
        this.formData.city = "";
        this.formData.state = "";
        this.formData.country = "";
      }
    },
  },

  setup() {
    const store = useStore();
    const supplier_data = ref<Array<Supplier>>([]);
    const address_title_data = ref([]);
    const manufacturer_data = ref([]);
    const account_executive_data = ref([]);
    const category_data_with_parent = ref<Array<Category>>([]);
    const expeceted_delivery_data = ref([]);
    const standardJson = ref<any>([]);
    const cat_attr_count = ref(0);
    const structure_data = ref<any>([]);
    const table_data = ref<any>([]);
    const standard_data = ref([]);
    const my_data_id = ref(0);

    interface Category {
      category_id: string;
      category_name: string;
      is_next_child_present: number;
    }

    interface Supplier {
      company_id: number;
      company_name: string;
      company_name_search: string;
      company_name_alternative: string;
      company_nob_id: number;
      company_nob_name: string;
      company_group_type_id: number;
      company_group_type_name: number;
      company_industry_type_id: number;
      company_industry_type_name: string;
      company_business_type_id: number;
      company_business_type_name: string;
      company_roc_id: number;
      company_roc_name: string;
      company_doi: string;
      company_cin_llpin: string;
      company_registration_number: string;
      company_pan: string;
      company_website: string;
      company_verified_yes_no: number;
      company_trade_type_id: number;
      active: number;
    }

    const uom_data = ref([]);
    const loadingPin = ref<boolean>(false);
    const mode_data = ref([]);
    const dialogSelectProductTableVisible = ref(false);
    const myData = ref<MyDataStructure[]>([]);
    const incoterms_data = ref([]);
    const payment_term_data = ref([]);

    onMounted(async () => {
      setCurrentPageBreadcrumbs("Create Sales Enquiry", []);
      getCategoryData(0, "");
    });

    const formRef = ref<null | HTMLFormElement>(null);
    const formAddProductRef = ref<null | HTMLFormElement>(null);
    const loading = ref<boolean>(false);
    const customer_data = ref<Array<any>>([]);
    const individual_customer_data = ref<Array<any>>([]);
    const customer_branch_data = ref<Array<any>>([]);
    const router = useRouter();

    getUOM();
    getIncoterms();
    getPaymentTerms();
    getModes();
    getDeliveryTerms();
    getAccountExecutiveData();

    function deleteRow(row) {
      var index = myData.value
        .map((deteRow) => {
          return deteRow.id;
        })
        .indexOf(row?.id);
      myData.value.splice(index, 1);
      if (myData.value.length === 0) {
        my_data_id.value = 0;
      }
    }

    const formData = ref({
      attribute_name: [],
      standard_select: {},
      standard_attr_select: "",
      customer_type: 1,
      customer_select: "",
      individual_customer_select: "",
      customer_branch_select: "",
      mode_select: "",
      payment_terms_select: "",
      incoterms_select: null,
      delivery_terms_select: "",
      account_executive_select: "",
      enquiry_date: new Date(),
      address_line_1: "",
      address_line_2: "",
      pincode: "",
      pincode_id: 0,
      city: "",
      state: "",
      country: "",
      se_remark: "",
    });

    interface Tree {
      category_name: string;
      category_id: string;
      uom_name: string;
      uom_id: number;
    }
    const handleCheckChange = (
      data: Tree,
      checked: boolean,
      indeterminate: boolean
    ) => {
      debugger;
      resetProductForm(true);
      formAddProductRef?.value?.resetFields();
      formAddProductRef?.value?.clearValidate();
      formProductData.value["standard_select"] = "";
      formProductData.value["standard_attr_select"] = "";
      manufacturer_data.value = [];
      formProductData.value["category_select"] = data.category_id;
      formProductData.value["category_name"] = data.category_name;
      formProductData.value["uom_select"] = {
        uom_id: data.uom_id,
        uom_name: data.uom_name,
      };
      getCompanyList(), getStandardGradeJson(), getAttributeStructure();
      console.log(data, checked, indeterminate);
    };

    const rangeValidator = async (
      value,
      rowIndex,
      childIndex,
      min,
      max,
      fileld
    ) => {
      let val = parseFloat(value);
      let minNum = parseFloat(min);
      let maxNum = parseFloat(max);
      let input: any = document.getElementsByClassName(fileld)[0].children[0];
      let warning: any = document.getElementById(fileld);
      warning.innerHTML = "";
      input.style.borderColor = "";
      if ((!/^\d*\.?\d*$/.test(value))||
          (value < minNum || val > maxNum || Number.isNaN(val))) {
        formProductData.value.attribute_name[fileld] = "";
        warning.innerHTML = "Please enter value between " + min + "-" + max;
        input.style.borderColor = "red";
      }
    };

    const formProductData = ref({
      manufacturers_select: [],
      category_select: "",
      category_name: "",
      qty: "",
      remark: "",
      uom_select: {},
      attribute_name: [],
      standard_select: {},
      standard_attr_select: {},
    }) as any;

    interface MyDataStructure {
      id: number;
      mfg_company_json: Record<string, string | number>[];
      category_id: string;
      brand_ids: Record<string, string | number>[];
      required_qty: string;
      uom: Record<string, unknown>;
      remarks: string;
      specifications: Record<string, string | number>[];
      product_name: string;
      standard_id: number;
      standard_attribute_id: any;
      category_attribute_json: Record<string, string | number>[];
      product_descriptions: Record<any, any>;
    }

    const addressData = ref([
      {
        pincode: "",
        city: "",
        address_line_1: "",
        address_line_2: "",
        branch: "",
        state: "",
      },
    ]);

    const rules = ref({
      customer_select: [
        {
          required: true,
          message: "Customer Company is required",
          trigger: "change",
        },
      ],
      customer_branch_select: [
        {
          required: true,
          message: "Branch is required",
          trigger: "change",
        },
      ],
      mode_select: [
        {
          required: true,
          message: "Mode is required",
          trigger: "change",
        },
      ],
      payment_terms_select: [
        {
          required: true,
          message: "Payment term is required",
          trigger: "change",
        },
      ],
      incoterms_select: [
        {
          required: true,
          message: "Incoterm is required",
          trigger: "change",
        },
      ],
      delivery_terms_select: [
        {
          required: true,
          message: "Delivery term is required",
          trigger: "change",
        },
      ],
      account_executive_select: [
        {
          required: true,
          message: "Maintain by is required",
          trigger: "change",
        },
      ],
      enquiry_date: [
        {
          required: true,
          message: "Enquiry Date is required",
          trigger: "change",
        },
      ],
      address_line_1: [
        {
          required: true,
          message: "Mode is required",
          trigger: "change",
        },
      ],
      individual_customer_select: [
        {
          required: true,
          message: "Individual Name is required",
          trigger: "change",
        },
      ],
      pincode: [
        {
          required: true,
          message: "Pincode is required",
          trigger: "change",
        },
      ],
      city: [
        {
          required: true,
          message: "City is required",
          trigger: "change",
        },
      ],
      state: [
        {
          required: true,
          message: "State is required",
          trigger: "change",
        },
      ],
      country: [
        {
          required: true,
          message: "Country is required",
          trigger: "change",
        },
      ],
    });

    const productRules = ref({
      category_select: [
        {
          required: true,
          message: "Category is required",
          trigger: "change",
        },
      ],
      standard_select: [
        {
          required: true,
          type: "object",
          message: "Standard is required",
          trigger: "change",
        },
      ],
      standard_attr_select: [
        {
          required: true,
          message: "Grade is required",
          trigger: "change",
        },
      ],
      qty: [
        {
          required: true,
          message: "Quantity is required",
          trigger: "change",
        },
      ],
    });

    const setCompanyData = async (data) => {
      debugger;
      var user = JSON.parse(JwtService.getToken());
      let product_list = myData.value.map((item) => {
        return {
          category_id: item.category_id,
          mfg_company_json: item.mfg_company_json,
          standard_id: item.standard_id,
          standard_attribute_generic_id: item.standard_attribute_id,
          category_attribute_json: item.category_attribute_json,
          required_quantity: item.required_qty,
          uom_json: item.uom,
          remark: item.remarks,
        };
      });

      if (product_list.length <= 0) {
        console.log("validation failed");
        Swal.fire({
          text: "Please Add At Least One Product.",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }

      const db_data = {
        se_time: moment(data.enquiry_date).format("YYYY-MM-DD HH:mm:ss"),
        customer_type: data.customer_type,
        customer_company_id: data.customer_type ==1 ? data.customer_select : 0,
        customer_company_branch_id: data.customer_type ==1 ? data.customer_branch_select : 0,
        customer_contact_id: data.customer_type ==1 ? 0 : data.individual_customer_select,
        enquiry_type_id: 1,
        enquiry_mode_id: data.mode_select,
        incoterm_id: data.incoterms_select,
        payment_term_id: data.payment_terms_select,
        delivery_term_id: data.delivery_terms_select,
        address_line_1: data.address_line_1,
        address_line_2: data.address_line_2,
        pincode_id: data.pincode_id,
        pincode: "",
        remark: data.se_remark,
        user_id: user.user_id,
        product_data: product_list,
      };

      try {
        await store
          .dispatch(ActionsFi.CUST_CREATE_SALSE_ENQUIRY, db_data)

          .then(({ data, is_error, status }) => {
            debugger;
            loading.value = false;
            console.log(data);
            if ((!is_error && status && status === 200) || status === 201) {
              setTimeout(() => {
                loading.value = false;
                Swal.fire({
                  html: ""+

                      data?.se_no+

                      "<br>Sales Enquiry created successfully!",
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "apps-sales-enquiry" });
                });
              }, 2000);
            } else {
              setTimeout(() => {
                loading.value = false;
                Swal.fire({
                  text: data?.errorMessage ? data?.errorMessage : "Eror",
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Ok, got it!",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then();
              }, 2000);
            }
          })
          .catch(({ response }) => {
            loading.value = false;
            console.log(response);
            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          });
      } catch (e) {
        loading.value = false;
        console.log(e);
      }
    };

    const submit = async () => {
      loading.value = true;
      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate(async (valid) => {
        if (valid) {
          await setCompanyData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    const submitProduct = async () => {
      loading.value = true;
      if (!formAddProductRef.value) {
        loading.value = false;
        return;
      }

      formAddProductRef.value.validate(async (valid) => {
        if (valid) {
          addProduct();
        } else {
          loading.value = false;
          return false;
        }
      });
    };

    async function getUOM() {
      await store
        .dispatch(Actions.CUST_PRODUCT_UOM)
        .then(({ data }) => {
          uom_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getCategoryData(id, type) {
      await store
        .dispatch(Actions.CUST_GET_CATEGORY_PARENT_LIST)
        .then(({ data }) => {
          debugger;
          category_data_with_parent.value = data ? data : [];
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    function Required() {
      return { color: "red" };
    }
    async function getIncoterms() {
      await store
        .dispatch(Actions.CUST_PRODUCT_INCOTERM)
        .then(({ data }) => {
          incoterms_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getAccountExecutiveData() {
      await store
        .dispatch(ActionsFi.CUST_GET_MAINTAIN_BY_LIST)
        .then(({ data }) => {
          account_executive_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getDeliveryTerms() {
      await store
        .dispatch(Actions.CUST_PRODUCT_DELIVERY_TERM)
        .then(({ data }) => {
          expeceted_delivery_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getPaymentTerms() {
      await store
        .dispatch(Actions.CUST_GET_PAYMENT_TERM)
        .then(({ data }) => {
          debugger;
          payment_term_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getModes() {
      await store
        .dispatch(ActionsFi.CUST_GET_MODE_INQUIRY)
        .then(({ data }) => {
          debugger;
          mode_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const resetForm = () => {
      formData.value = {
        attribute_name: [],
        standard_select: {},
        standard_attr_select: "",
        customer_type: 1,
        customer_select: "",
        individual_customer_select: "",
        customer_branch_select: "",
        mode_select: "",
        payment_terms_select: "",
        incoterms_select: null,
        delivery_terms_select: "",
        account_executive_select: "",
        enquiry_date: new Date(),
        address_line_1: "",
        address_line_2: "",
        pincode: "",
        pincode_id: 0,
        city: "",
        state: "",
        country: "",
        se_remark: "",
      };
      myData.value = [];
      formRef?.value?.resetFields();
      formRef?.value?.clearValidate();
    };

    const getStandardGradeJson = async () => {
      debugger;
      var values = {
        category_id: formProductData.value["category_select"],
        company_id: 0,
        company_branch_id: 0,
        brand_id: 0,
      };
      loading.value = true;
      await store
        .dispatch(Actions.CUST_GET_PRODUCT_STANDARD_JSON_DATA, values)
        .then((data) => {
          debugger;
          if (data.status === 200) standardJson.value = data.data;
          else standardJson.value = [];
          loading.value = false;
        })
        .catch(({ e }) => {
          console.log(e);
        });
    };

    async function getAttributeStructure() {
      debugger;

      var values = {
        category_id: formProductData.value["category_select"],
        company_id: 0,
        company_branch_id: 0,
        brand_id: 0,
      };
      loading.value = true;
      cat_attr_count.value = 0;
      await store
        .dispatch(
          Actions.CUST_GET_PRODUCT_ATTRIBUTE_STRUCTURE_BY_CATEGORY_ID,
          values
        )
        .then(({ data }) => {
          debugger;
          loading.value = false;

          let formStructure = data.map((item) => {
            if (item.input_value_required_yn === 0) {
              let value = {};
              value["parentLabel"] = item.attribute_label;
              value["input_value_required_yn"] = item.input_value_required_yn;
              let childAttr = item.sub_attributes.map((child) => {
                let subAttr = {
                  input_value_type: child.input_value_type,
                  attribute_label: child.attribute_label,
                  category_attribute_setting_id:
                    child.category_attribute_setting_id,
                  category_attribute_value: "",
                  min_value: child.min_value,
                  max_value: child.max_value,
                  input_value_required_yn: child.input_value_required_yn,
                  attribute_values: child.attribute_values,
                  attribute_name: child.attribute_label.toLowerCase(),
                };
                if (child.input_value_type === "RANGE") {
                  subAttr["range"] = {
                    min: parseFloat(child.min_value),
                    max: parseFloat(child.max_value),
                  };
                }
                cat_attr_count.value++;
                return subAttr;
              });
              value["subAttr"] = childAttr;
              return value;
            } else {
              let value = {
                input_value_type: item.input_value_type,
                attribute_label: item.attribute_label,
                category_attribute_setting_id:
                  item.category_attribute_setting_id,
                category_attribute_value: "",
                min_value: 0,
                max_value: 0,
                input_value_required_yn: item.input_value_required_yn,
                attribute_values: item.attribute_values,
                attribute_name: item.attribute_label.toLowerCase(),
              };
              value["parentLabel"] = "";
              if (item.input_value_type === "RANGE") {
                // value["range"] = {
                  value.min_value = parseFloat(item.min_value);
                  value.max_value = parseFloat(item.max_value);
                // };
              }
              cat_attr_count.value++;
              return value;
            }
          });
          structure_data.value = formStructure;
          let rule_stuctrue = {};
          formStructure.map((item) => {
            if (item.subAttr) {
              item.subAttr.map((attr) => {
                let label = attr.attribute_name;
                rule_stuctrue[label] = [
                  {
                    required: true,
                    message: attr.attribute_label + "  is required",
                    trigger: "change",
                  },
                ];
              });
            } else {
              let label = item.attribute_name;
              rule_stuctrue[label] = [
                {
                  required: true,
                  message: item.attribute_label + "  is required",
                  trigger: "change",
                },
              ];
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const resetProductForm = async (isCategoryChange = false) => {
      formProductData.value.manufacturers_select = [];
      formProductData.value.category_select = "";
      formProductData.value.category_name = "";
      formProductData.value.qty = "";
      formProductData.value.remark = "";
      formProductData.value.uom_select = {};
      formProductData.value.attribute_name = [];
      formProductData.value.standard_select = "";
      formProductData.value.standard_attr_select = {};
      manufacturer_data.value = [];
      standardJson.value = [];
      structure_data.value = [];
      table_data.value = [];
      standard_data.value = [];
      if (!isCategoryChange) {
        dialogSelectProductTableVisible.value = false;
      }
    };

    const addProduct = () => {
      debugger;
      let spec: any = [];
      let product_description = {} as any;

      let mfg_company_json = formProductData.value.manufacturers_select.map(
        (item: any) => {
          return {
            company_id: item.company_id,
            company_name: item.manufacturer_name,
          };
        }
      );

      let category_attributes = formProductData.value.attribute_name;
      let selected_attr_count = Object.keys(category_attributes).length;
      let attribute_emapty = false;
      for (let key in category_attributes) {
        let value: any = category_attributes[key];
        if (typeof value === "string" && !value.trim()) {
          attribute_emapty = true;
          break;
        }
      }

      if (selected_attr_count < cat_attr_count.value || attribute_emapty) {
        Swal.fire({
          text: "Plese fill all the category attributes !",
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
        loading.value = false;
        return;
      }

      let category_attributes_array = [] as any;
      for (let key in category_attributes) {
        let keys = key.split(",");
        let category_attribute_setting_id = parseInt(keys[1]);
        let attribute_value: any = category_attributes[key];
        let new_attribute_obj = {};
        if (
          typeof attribute_value === "object" &&
          Object.keys(attribute_value).includes("category_attribute_generic_id")
        ) {
          new_attribute_obj = Object.assign(
            { category_attribute_setting_id: category_attribute_setting_id },
            attribute_value
          );
          category_attributes_array.push(new_attribute_obj);
          let keyName =
            keys[0].toString().charAt(0).toUpperCase() +
            keys[0].toString().slice(1);
          product_description[keyName] =
            attribute_value.category_attribute_value;
        } else {
          new_attribute_obj["category_attribute_setting_id"] =
            category_attribute_setting_id;
          new_attribute_obj["category_attribute_generic_id"] = 0;
          new_attribute_obj["category_attribute_value"] = attribute_value;
          category_attributes_array.push(new_attribute_obj);
          for (let item of structure_data.value) {
            if (item["parentLabel"] && Object.keys(item["subAttr"].length)) {
              product_description[item["parentLabel"]] =
                product_description[item["parentLabel"]] &&
                Object.keys(product_description[item["parentLabel"]]).length !==
                  0
                  ? Object.assign({}, product_description[item["parentLabel"]])
                  : {};
              for (let data of item["subAttr"]) {
                if (
                  data["category_attribute_setting_id"] ===
                  category_attribute_setting_id
                ) {
                  product_description[item["parentLabel"]][
                    data["attribute_label"]
                  ] = attribute_value;
                }
              }
            }else if(item['parentLabel'] == "" && 
            item['category_attribute_setting_id'] == category_attribute_setting_id){
              product_description[item["attribute_name"]] = attribute_value;
            }
          }
        }
      }
      if (formProductData.value.standard_select["standard_no"]) {
        product_description["standard_name"] = formProductData.value
          .standard_select["standard_no"]
          ? formProductData.value.standard_select["standard_no"]
          : "";
      }
      if (
        formProductData.value.standard_attr_select["standard_attribute_value"]
      ) {
        product_description["grade"] = formProductData.value
          .standard_attr_select["standard_attribute_value"]
          ? formProductData.value.standard_attr_select[
              "standard_attribute_value"
            ]
          : "";
      }
      my_data_id.value++;
      myData.value.push({
        id: my_data_id.value,
        category_id: formProductData.value["sub_category_select"]
          ? formProductData.value["sub_category_select"]
          : formProductData.value["category_select"],
        brand_ids: formProductData.value["brand_select"],
        required_qty: formProductData.value["qty"],
        uom: formProductData.value["uom_select"],
        remarks: formProductData.value["remark"],
        specifications: spec,
        product_name: formProductData.value["category_name"],
        mfg_company_json: mfg_company_json,
        standard_id: formProductData.value.standard_select["standard_id"]
          ? formProductData.value.standard_select["standard_id"]
          : 0,
        standard_attribute_id: formProductData.value.standard_attr_select[
          "standard_attribute_generic_id"
        ]
          ? formProductData.value.standard_attr_select[
              "standard_attribute_generic_id"
            ]
          : 0,
        category_attribute_json: category_attributes_array,
        product_descriptions: product_description,
      });
      loading.value = false;
      resetProductForm();
    };

    const openDilogue = () => {
      dialogSelectProductTableVisible.value = true;
    };

    const getCompanyList = async () => {
      let values = {
        category_id: formProductData?.value?.category_select,
        page: 1,
        records_per_page: 1000,
      };
      await store
        .dispatch(Actions.CUST_GET_CATEGORY_MANUFACTURER_LIST_DATA, values)
        .then(({ data }) => {
          debugger;
          manufacturer_data.value = data.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    };

    const pin = ref([]);
    const getPincodeData = async (search_data) => {
      loadingPin.value = true;

      const db_data = { search_term: search_data };
      if (db_data.search_term) {
        await store
          .dispatch(Actions.CUST_GET_USER_PROFILE_PINCODE_SEARCH, db_data)
          .then(({ data }) => {
            pin.value = data;
            for (let i = 0; i < data.length; i++) {
              formData.value.city = data[i].city_name;
              formData.value.state = data[i].state_name;
              formData.value.country = data[i].country_name;

              formData.value.pincode_id = data[i].pincode_id;
              loadingPin.value = false;
            }
          })
          .catch(({ response }) => {
            Swal.fire({
              text: "Sorry, It's Invalid Pincode!",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });

            loadingPin.value = false;
          });
      }
    };

    const ClickPincodeData = async (pin_details) => {
      formData.value.city = pin_details.city_name;
      formData.value.state = pin_details.state_name;
      formData.value.country = pin_details.country_name;
    };

    const onCompnyTypeSelct = (selectedType) => {
      formData.value.customer_type = selectedType;
    };

    async function getCustomerCompanyList(query: string) {
      debugger;
      if (query.length < 3) {
        return;
      }
      let values = { search_term: query };
      await store
        .dispatch(Actions.CUST_GET_SUPPLIER_DROPDOWN_LIST, values)
        .then(({ data }) => {
          debugger;
          customer_data.value = data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    async function getIndividualCustomerList(query: string) {
      debugger;
      if (query.length < 3) {
        return;
      }
      let values = {
        contact_id:0,
        page : 1,
        records_per_page : 10,
        search_term: query };
      await store
        .dispatch(Actions.CUST_BUSINESS_ENTITY_INDIVIDUAL_LIST, values)
        .then(({ data }) => {
          debugger;
          individual_customer_data.value = data.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }


    async function getCustomerBranchList() {
      let values = {
        company_id: formData.value["customer_select"],
        company_branch_id: 0,
        page: 1,
        records_per_page: 1000,
      };
      await store
        .dispatch(Actions.CUST_GET_BRANCH_LIST, values)
        .then(({ data }) => {
          debugger;
          customer_branch_data.value = data?.result_list;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    }

    const validateInput = (fieldName) => {
      if (!/^\d*\.?\d*$/.test(formProductData.value[fieldName]) ||
          formProductData.value[fieldName] == 0
          ){
        formProductData.value[fieldName] = formProductData.value[fieldName].slice(0, -1);
        return
      }
    }

    return {
      formData,
      formProductData,
      rules,
      productRules,
      submit,
      submitProduct,
      formRef,
      formAddProductRef,
      loading,
      uom_data,
      incoterms_data,
      payment_term_data,
      addProduct,
      myData,
      deleteRow,
      getIncoterms,
      address_title_data,
      Required,
      getCompanyList,
      addressData,
      supplier_data,
      openDilogue,
      resetProductForm,
      expeceted_delivery_data,
      account_executive_data,
      dialogSelectProductTableVisible,
      mode_data,
      loadingPin,
      manufacturer_data,
      standardJson,
      structure_data,
      table_data,
      standard_data,
      handleCheckChange,
      rangeValidator,
      getPincodeData,
      pin,
      ClickPincodeData,
      getCustomerCompanyList,
      getIndividualCustomerList,
      getCustomerBranchList,
      onCompnyTypeSelct,
      customer_data,
      individual_customer_data,
      customer_branch_data,
      category_data_with_parent,
      resetForm,
      validateInput
    };
  },
});
